import React, { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import {
  Container,
  Stack,
  TextField,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useApi from "../../../hooks/useApi";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";
import { useUser } from "../../../hooks/useUser";
import Audio from "react-loading-icons/dist/esm/components/audio";
import { getDateAndHour } from "../../../utils/date";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const RetirosProductor = () => {
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi, loadingApi } = useApi();
  const [monto, setMonto] = useState({});
  const [historial, setHistorial] = useState([]);
  const { user, updateUser } = useUser();

  useEffect(() => {
    loadApi("productor/retiros", true, "get").then((response) => {
      setHistorial(response.data);
    });
  }, [user]);

  const handleRetirar = () => {
    loadApi("productor/retiros", true, "post", monto)
      .then((response) => {
        showSnack(response.data.message);
        updateUser();
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack
          sx={{
            height: "calc(100vh - 96px)",
            gap: "20px",
            alignItems: "center",
            boxSizing: "border-box",
            pt: 2,
          }}
        >
          <Stack
            sx={{
              p: 4,
              borderRadius: "20px",
              backgroundColor: "#85bb65 ",
              maxWidth: "510px",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <Typography variant="h4" textAlign="center" color="white">
              $ {user.profile.credits} USD
            </Typography>
          </Stack>
          <Stack sx={{ gap: "10px", maxWidth: "510px", width: "100%" }}>
            <TextField
              variant="standard"
              label="Digite valor a retirar"
              type="number"
              onChange={(e) => setMonto({ monto: e.target.value })}
            ></TextField>
            <Typography
              variant="body2"
              color="grayText"
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <ErrorOutlineIcon /> Valor minimo a retirar $30.00 USD, los retiros se hacen manuales y se procesan detro de 48 horas.
            </Typography>
            <LoadingButton
              sx={{ borderRadius: "10px", p: "10px 20px" }}
              loading={loadingApi.includes("post__productor/retiros")}
              onClick={() => handleRetirar()}
            >
              Retirar
            </LoadingButton>
          </Stack>
          <Stack pb={6} pt={2}>
            <Typography variant="h4">Historial de retiros</Typography>
            <Stack alignItems="end" sx={{ gap: "20px", mt: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> ID retiro </StyledTableCell>
                      <StyledTableCell> Fecha </StyledTableCell>
                      <StyledTableCell> Monto </StyledTableCell>
                      <StyledTableCell> Estado </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingApi.includes("get__productor/retiros") &&
                      (historial.length === 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5}>
                            <Audio fill="#F27224" />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {!loadingApi.includes("get__productor/retiros") &&
                    historial.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <Typography variant="h5">
                            No hay resultados
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      historial.map((item, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{item.id}</StyledTableCell>
                          <StyledTableCell>{getDateAndHour(item.createdAt)}</StyledTableCell>
                          <StyledTableCell>$ {item.monto} USD</StyledTableCell>
                          <StyledTableCell>{item.status}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default RetirosProductor;
