import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function Step1({ changeForm, mainData }) {
  const tiposArtista = [
    "Solista",
    "Grupo Musical",
    "Sello Discográfico",
    "Manager o RP",
  ];

  return (
    <Stack alignItems="center">
      <Stack
        spacing={2.3}
        width={{ xs: "100%", sm: "90%", md: "70%", lg: "50%" }}
      >
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa el tipo de artista que eres:
          </Typography>
          <Select
            value={mainData?.tipo || ""}
            displayEmpty
            onChange={changeForm("tipo")}
          >
            <MenuItem value="" disabled>
              Selecciona una...
            </MenuItem>
            {tiposArtista.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">Ingresa el nombre del proyecto:</Typography>
          <TextField
            value={mainData?.nombre_media || ""}
            onChange={changeForm("nombre_media")}
            fullWidth
          ></TextField>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa el nombre de la persona encargada:
          </Typography>
          <TextField
            value={mainData?.nombre_encargado || ""}
            onChange={changeForm("nombre_encargado")}
            fullWidth
          ></TextField>
        </Stack>
      </Stack>
    </Stack>
  );
}
