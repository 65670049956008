import {
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Table,
} from "@mui/material";
import styled from "@emotion/styled";
import Audio from "react-loading-icons/dist/esm/components/audio";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    padding: "5px 0",
  },
}));

const TableStyled = ({
  headers = [],
  data = [],
  name = "name",
  loading = false,
}) => {
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  return (
    <>
      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <StyledTableCell key={i}> {header.label} </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && data.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={headers.length}>
                  <Audio fill="#F27224" />
                </StyledTableCell>
              </TableRow>
            )}

            {!loading && data.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={headers.length}>
                  <Typography variant="h5">No hay resultados</Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              data.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    ":hover": {
                      backgroundColor: "#f1f1f1",
                      cursor: "pointer",
                    },
                  }}
                >
                  {headers.map(({ ref }, index) => (
                    <StyledTableCell
                      sx={{ fontWeight: index === 0 ? 700 : 400 }}
                    >
                      {["start_discount", "end_discount"].includes(ref)
                        ? formatDate(item[ref])
                        : ref === "radar_coin"
                        ? item[ref] + " RC"
                        : item[ref]}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
};

export default TableStyled;
