import { Stack, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useApi from "../../hooks/useApi";
import youtubeToEmbed from "../../utils/youtubeToEmbed";
import spotifyToEmbed from "../../utils/spotifyToEmbed";

const Embed = () => {
  const { loadApi, loadingApi } = useApi();
  const [song, setSong] = useState();
  const { songid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadApi(`general/canciones/${songid}`, false, "get")
      .then((res) => {
        setSong(res.data);
      })
      .catch((error) => {
        navigate("/error");
      });
  }, []);

  return (
    <>
      {song && (
        <Stack height="100vh">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            flexWrap="wrap"
          >
            <Typography variant="subtitle1" color="grayText">
              {song.profile.nombre_media}
            </Typography>
            {/* separador */}
            <Box
              sx={{ width: "10px", height: "2px", backgroundColor: "grayText" }}
            ></Box>
            <Typography variant="subtitle1" ontWeight={500} color="grayText">
              "{song.nombre}"
            </Typography>
            <img
              src={`https://flagcdn.com/${song.profile.pais}.svg`}
              alt="País"
              width="25"
              height="15"
            />
          </Stack>
          <Typography variant="body1" fontWeight={400} color="grayText">
            <pre
              style={{
                fontFamily: "'Roboto', Helvetica, Arial",
                fontWeight: "400",
              }}
            >
              {song.descripcion}
            </pre>
          </Typography>
          {/* embed canción */}
          {youtubeToEmbed(song.url) ? (
            <Box
              id={`iframePrincipal2`}
              width="100%"
              height="100%"
              sx={{ iframe: { borderRadius: "4px/6.7px" } }}
            >
              <iframe
                width="100%"
                height="100%"
                src={youtubeToEmbed(song.url)}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                alowFullScreen
              ></iframe>
            </Box>
          ) : spotifyToEmbed(song.url) ? (
            <Box
              id={`iframePrincipal2`}
              width="100%"
              sx={{ flexGrow: 1, iframe: { borderRadius: "4px/6.7px" } }}
            >
              <iframe
                width="100%"
                height="100%"
                src={spotifyToEmbed(song.url)}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                alowFullScreen
              ></iframe>
            </Box>
          ) : (
            <Box
              id={`iframePrincipal2`}
              width="100%"
              sx={{ flexGrow: 1, iframe: { borderRadius: "4px/6.7px" } }}
            >
              <iframe
                width="100%"
                height="100%"
                src={song.url}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                alowFullScreen
              ></iframe>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export default Embed;
