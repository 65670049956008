import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container, Grid, Typography } from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, productor, respuesta) {
  return { name, productor, respuesta };
}

const rows = [
  createData("Nombre De Cancion", "Productor", "Respuesta"),
  createData("Nombre De Cancion", "Productor", "Respuesta"),
  createData("Nombre De Cancion", "Productor", "Respuesta"),
  createData("Nombre De Cancion", "Productor", "Respuesta"),
  createData("Nombre De Cancion", "Productor", "Respuesta"),
];

export default function CustomizedTables() {
  return (
    <>
      <GlobalHeader />
      <Container>
        <Grid container spacing={5}>
          <Grid container item spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary.main" fontWeight="900">
                Historial de Envios
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1000 }} aria-label="customized table">
                  {/* 1 seccion */}
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Cancion enviada</StyledTableCell>
                      <StyledTableCell align="right">Productor</StyledTableCell>
                      <StyledTableCell align="right">Respuesta</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {/* 2 seccion */}
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.productor}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.respuesta}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
