import { isURL } from "validator";

const spotifyToEmbed = (url) => {
  // Verificar que la URL sea válida
  if (!isURL(url)) {
    return;
  }

  var regex = /^https?:\/\/open\.spotify\.com\/intl-es\/track\/[a-zA-Z0-9]+/i;
  var regexAlt = /^https?:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+/i;
  var regex2 = /track\/([a-zA-Z0-9]+)/;
  if (regex.test(url) || regexAlt.test(url)) {
    var match = url.match(regex2);
    if (match && match.length > 1) {
      return 'https://open.spotify.com/embed/track/' + match[1];
    }
    return;
  }
};

export default spotifyToEmbed;
