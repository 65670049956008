import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Container,
  Grid,
  Rating,
  Typography,
  Stack,
  Backdrop,
  Modal,
} from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import Audio from "react-loading-icons/dist/esm/components/audio";
import CircularProgress from "@mui/material/CircularProgress";
import { BorderColor } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function ViewArtistSends() {
  const [mainData, setMainData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { loadApi, loadedApi, loadingApi } = useApi();

  useEffect(() => {
    getEnvios();
  }, []);

  const getEnvios = () => {
    loadApi("artista/envios", true, "get")
      .then((response) => {
        setMainData(
          response.data.map((data) => {
            return {
              name: data.cancion,
              productor: data.productor,
              respuesta: data.status,
              calificado: data.calificado,
              productorId: data.productorId,
              id: data.id,
              feedback: data.feedback,
              url_post: data.url_post,
            };
          }) || []
        );
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  };

  const handleRating = (data) => {
    setOpen(true);
    loadApi("artista/calificarProductor", true, "put", data)
      .then((res) => {
        showSnack(res.data.message);
        getEnvios();
        setOpen(false);
      })
      .catch((e) => {
        showSnack(e.message, "error");
        setOpen(false);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (row) => {
    setDataModal(row);
    setOpenModal(true);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={styleModal}>
          <Stack>
            {dataModal.respuesta === "approve" && (
              <Stack>
                <Typography textAlign="center">Post recibido</Typography>
                <Box
                  sx={{
                    padding: "10px 10px 10px 20px",
                    borderRadius: "10px",
                    borderTopLeftRadius: "0",
                    backgroundColor: "#f1f1f1",
                    mt: 1,
                    "::after": {
                      content: "''",
                      position: "absolute",
                      left: "22px",
                      top: "54.5px",
                      border: "10px solid",
                      borderColor: "transparent transparent  #f1f1f1",
                    },
                  }}
                >
                  <Typography
                    sx={{ wordWrap: "break-word", textDecoration: "underline" }}
                    variant="subtitle1"
                    color="GrayText"
                    fontWeight={400}
                  >
                    <a href={dataModal.url_post} target="_blank" rel="nofollow noreferrer">
                      {dataModal.url_post}
                    </a>
                  </Typography>
                </Box>
              </Stack>
            )}
            <Typography textAlign="center">Feedback recibido</Typography>
            <Box
              sx={{
                padding: "10px 10px 10px 20px",
                borderRadius: "10px",
                borderTopLeftRadius: "0",
                backgroundColor: "#f1f1f1",
                mt: 1,
                "::after": {
                  content: "''",
                  position: "absolute",
                  left: "22px",
                  top: "54.5px",
                  border: "10px solid",
                  borderColor: "transparent transparent  #f1f1f1",
                },
              }}
            >
              <Typography
                sx={{ wordWrap: "break-word" }}
                variant="subtitle1"
                color="GrayText"
                fontWeight={400}
              >
                {dataModal.feedback || "El curador no anexó feedback"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GlobalHeader />
      {!loadedApi.includes("get__artista/envios") && mainData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : (
        <Container>
          <Grid container spacing={5}>
            <Grid container item spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" fontWeight="900">
                  Historial de Envios
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 1000 }} aria-label="customized table">
                    {/* 1 seccion */}
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Cancion enviada</StyledTableCell>
                        <StyledTableCell align="center">
                          Curador
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Respuesta
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Calificación
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    {/* 2 seccion */}
                    <TableBody>
                      {mainData.map((row, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.productor}
                          </StyledTableCell>
                          {row.respuesta === "rejected" ? (
                            <StyledTableCell
                              align="center"
                              onClick={() => handleOpenModal(row)}
                              sx={{ cursor: "pointer" }}
                            >
                              <Typography color="red">RECHAZADO</Typography>
                            </StyledTableCell>
                          ) : row.respuesta === "approve" ? (
                            <StyledTableCell
                              align="center"
                              onClick={() => handleOpenModal(row)}
                              sx={{ cursor: "pointer" }}
                            >
                              <Typography color="green">APROBADO</Typography>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center">
                              <Typography color="grayText">
                                PENDIENTE
                              </Typography>
                            </StyledTableCell>
                          )}
                          {row.calificado ? (
                            <StyledTableCell align="center">
                              <Typography variant="subtitle1" color="green">
                                CALIFICADO
                              </Typography>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="right">
                              <Stack
                                sx={{
                                  "& > legend": { mt: 2 },
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <Rating
                                  onChange={(event, newValue) => {
                                    handleRating({
                                      rating: newValue,
                                      productorId: row.productorId,
                                      envioId: row.id,
                                    });
                                  }}
                                />
                              </Stack>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
