import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function Step1P({ changeForm, mainData }) {
  const tiposProductor = [
    "Blog de música",
    "Revista",
    "Periódico",
    "Estación de radio",
    "Influencer",
    "Canal de TV",
  ];

  return (
    <Stack alignItems="center">
      <Stack
        spacing={2.3}
        width={{ xs: "100%", sm: "90%", md: "70%", lg: "50%" }}
      >
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa el tipo de curador que eres:
          </Typography>
          <Select
            value={mainData?.tipo || ""}
            displayEmpty
            onChange={changeForm("tipo")}
          >
            <MenuItem value="" disabled>
              Selecciona una...
            </MenuItem>
            {tiposProductor.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">Ingresa el nombre del medio:</Typography>
          <TextField
            value={mainData?.nombre_media || ""}
            onChange={changeForm("nombre_media")}
            fullWidth
          ></TextField>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">
            Ingresa una breve descripción del medio:
          </Typography>
          <TextField
            multiline
            rows={3}
            value={mainData?.descripcion || ""}
            onChange={changeForm("descripcion")}
            fullWidth
          ></TextField>
        </Stack>
      </Stack>
    </Stack>
  );
}
