const plan = [
  {
    name: "1 Radarcoin",
    fullname: "Envío social",
    radarCoin: 1,
    amount: 1,
    description:
      "Este tipo de envío es solamente para que los curadores compartan la canción en redes sociales o una playlist de spotify.",
  },
  {
    name: "3 Radarcoin",
    fullname: "Envío Editorial",
    radarCoin: 3,
    amount: 3,
    description:
      "Este tipo de envío es para solicitar una nota editorial en la página web del curador y lleva amplificación en redes sociales.",
  },
  {
    name: "5 Radarcoin",
    fullname: "Solicitud de Entrevista",
    radarCoin: 5,
    amount: 6,
    description:
      "Este tipo de envío es para solicitar una entrevista, ya sea escrita o en video con el curador.",
  },
];

export const verifyPlan = (amount) => {
    return plan.find((element) => element.amount === amount )
}
