import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Modal,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Rating,
  Fab,
  Slide,
} from "@mui/material";
import { Audio } from "react-loading-icons";
import GlobalHeader from "../../../components/GlobalHeader";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../../hooks/useUser";
import {
  FaSpotify,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaFacebook,
} from "react-icons/fa";
import CardsProductores from "../../../components/cardsProductores";
import NavigationIcon from "@mui/icons-material/Navigation";

//style modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: { xs: 300, sm: "400" },
  maxHeight: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  "::-webkit-scrollbar": {
    display: "none",
  },
};

//array de opciones
const options = [
  {
    name: "1 Radarcoin",
    radarCoin: 1,
    amount: 1,
    description:
      "Este tipo de envío es solamente para que los curadores compartan la canción en redes sociales o una playlist de spotify.",
  },
  {
    name: "3 Radarcoin",
    radarCoin: 3,
    amount: 3,
    description:
      "Este tipo de envío es para solicitar una nota editorial en la página web del curador y lleva amplificación en redes sociales.",
  },
  {
    name: "5 Radarcoin",
    radarCoin: 5,
    amount: 6,
    description:
      "Este tipo de envío es para solicitar una entrevista, ya sea escrita o en video con el curador.",
  },
];

const colors = {
  spotify: "#81b71a",
  youtube: " #c4302b",
};

const socialNetwork = [
  { name: "facebook", Icon: FaFacebook },
  { name: "instagram", Icon: FaInstagram },
  { name: "youtube", Icon: FaYoutube },
  { name: "spotify", Icon: FaSpotify },
  { name: "tiktok", Icon: FaTiktok },
];

export default function VistaAInfluencer() {
  const [mainData, setMainData] = useState([]);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { user, updateUser } = useUser();
  const { loadApi, loadedApi, loadingApi } = useApi();
  const { songId } = useParams();
  const [favorites, setFavorites] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({ amount: 1, radarCoin: 1 });
  const [search, setSearch] = useState();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [info, setInfo] = useState(null);
  const [value, setValue] = useState(2);
  //Seleccionar uno o mas productores
  const [producers, setProducers] = useState([]);

  useEffect(() => {
    getProductores();
    loadFavorites();
  }, [user]);

  //Mostrar productores
  const getProductores = () => {
    loadApi("artista/productores", true, "get")
      .then((response) => {
        setMainData(response.data || []);
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  };

  //Enviar cancion
  const sendSong = async () => {
    if (!producers[0].amount) {
      showSnack("Selecciona una opción", "error");
      return;
    }
    try {
      const { message } = await loadApi(
        "artista/envios",
        true,
        "post",
        producers.length > 0 ? { producers } : { producers: [data] }
      ).then((data) => data.data);
      showSnack(message);
      updateUser();
      handleClose();
    } catch (error) {
      showSnack(error.message, "error");
    }
  };

  //OBTENER FAVORITOS
  const loadFavorites = () => {
    loadApi("artista/favoritos", true, "get").then((res) => {
      setFavorites(res.data);
    });
  };

  //AGREGAR FAVORITOS
  const updateFavorite = (id) => {
    let favoritos;
    if (user.profile.favorites) {
      favoritos = user.profile.favorites;
      if (favoritos.includes(id))
        favoritos = favoritos.filter((item) => item !== id);
      else favoritos.push(id);
    } else favoritos = [id];
    if(favoritos.length === 0)
      favoritos = null;
    loadApi("artista/favoritos", true, "put", { favorites: favoritos }).then(
      (res) => {
        SnackAlert(res.data.message);
        updateUser();
      }
    );
  };

  //ABRIR MODAL
  const handleOpen = (productorId, productor) => {
    productor ? setInfo(productor) : setInfo(null);
    setData({ ...data, productorId, songId });
    setOpen(true);
  };

  //EEGIR PLAN
  const handleOpciones = (amount, radarCoin) => {
    if (producers.length > 0) {
      setProducers((productores) => {
        let newArray = [];
        for (let prod of productores) {
          newArray.push({ ...prod, amount, radarCoin });
        }
        return newArray;
      });
    } else {
      setData({ ...data, amount, radarCoin });
    }
  };

  //seacrch
  const handleSearch = (value) => {
    clearTimeout(typingTimeout);
    setSearch(value);

    if (value) {
      const newTypingTimeout = setTimeout(() => {
        const filteredPeople = mainData.filter((person) =>
          person.profile?.nombre_media
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setMainData(filteredPeople);
      }, 1000);

      setTypingTimeout(newTypingTimeout);
    } else {
      getProductores();
    }
  };

  //Seleccionar productores

  const handleSelectProducers = ({ checked }, { id }) => {
    if (checked) {
      setProducers((props) => [...props, { productorId: id, songId }]);
    } else {
      const pre_producers = producers.filter((pro) => pro.productorId !== id);
      setProducers(pre_producers);
    }
  };

  return (
    <>
      <GlobalHeader />
      {!loadedApi.includes("get__artista/productores") &&
      mainData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : (
        <Container>
          <Grid container>
            <Grid container item xs={12}>
              {/* TITULO */}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    color="primary.main"
                    fontWeight="900"
                    fontSize={{ xs: "24px", sm: "34px" }}
                  >
                    FAVORITOS
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      variant="standard"
                      label="Buscar..."
                      type="text"
                      autoComplete={false}
                      onChange={(e) => handleSearch(e.target.value)}
                    ></TextField>
                  </Stack>
                </Stack>
              </Grid>
              {/* CONTENEDOR DE FAVORITOS */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    my: 4,
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(230px, 1fr) )",
                    justifyItems: "center",
                    alignItems: "start",
                    gap: 2,
                    pb: 2,
                  }}
                >
                  {favorites.length === 0 ? (
                    <Typography variant="h6" fontWeight="500">
                      No tienes favoritos
                    </Typography>
                  ) : (
                    favorites?.map((dataItem, i) => (
                      //card todos
                      <Stack
                        sx={{
                          width: "200px",
                          p: "15px",
                          gap: "10px",
                          borderRadius: "10px",
                          justifyContent: "space-between",
                          ":hover": {
                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px",
                          },
                        }}
                        key={i}
                      >
                        <Stack gap="10px">
                          {/* header */}
                          <Stack>
                            <img
                              src={dataItem?.profileimg || "/assets/AVATAR.jpg"}
                              width="100%"
                              style={{
                                borderRadius: "10px",
                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 15px",
                              }}
                            />
                          </Stack>
                          {/* body */}
                          <Stack>
                            <Stack direction="row">
                              <Typography
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: "170px",
                                }}
                              >
                                {dataItem.nombre_media}
                              </Typography>
                              <img
                                src={`https://flagcdn.com/${dataItem.pais}.svg`}
                                alt="País"
                                width="30"
                                height="20"
                              />
                            </Stack>
                            <Typography
                              variant="body1"
                              color="grayText"
                              fontWeight={400}
                            >
                              {dataItem.tipo}
                            </Typography>
                            <Stack>
                              <Typography variant="p" fontWeight={400}>
                                {dataItem?.generos_musicales ||
                                  [].map((item, i) => {
                                    return !(
                                      i !==
                                      JSON.parse(dataItem.generos_musicales)
                                        .length -
                                        1
                                    )
                                      ? `${item}.`
                                      : `${item}, `;
                                  })}
                              </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end">
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "400",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  ":hover": { color: "#F27224" },
                                }}
                                onClick={() =>
                                  handleOpen(dataItem.userId, dataItem)
                                }
                              >
                                Ver mas
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* button */}
                        <Stack>
                          <Button onClick={() => handleOpen(dataItem.userId)}>
                            Enviar
                          </Button>
                        </Stack>
                      </Stack>
                    ))
                  )}
                </Box>
              </Grid>
              {/* CONTENEDOR DE ITEMS */}
              <Grid item xs={12}>
                <Typography variant="h4" color="primary.main" fontWeight="900">
                  CURADORES
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    my: 4,
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(230px, 1fr) )",
                    justifyItems: "center",
                    alignItems: "start",
                    gap: 2,
                    pb: 2,
                  }}
                >
                  {mainData.map((dataItem, i) => (
                    //card todos
                    <Box key={i} sx={{ cursor: "pointer" }}>
                      <CardsProductores
                        dataItem={dataItem}
                        handleOpen={handleOpen}
                        updateFavorite={updateFavorite}
                        loading={loadingApi.includes("put__artista/favoritos")}
                        handleSelectProducers={handleSelectProducers}
                        producers={producers}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Slide
        direction="up"
        in={producers.length > 0}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{ position: "fixed", bottom: 30, right: 30 }}>
          <Fab variant="extended" color="primary" onClick={handleOpen}>
            <NavigationIcon sx={{ mr: 1 }} />
            Enviar
          </Fab>
        </Box>
      </Slide>
      {/* Modal información */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!info ? (
          <Box sx={style}>
            <Typography variant="h6" textAlign="center">
              Seleccione una opción
            </Typography>
            <FormControl>
              <RadioGroup name="radarCoins">
                {options.map((item, i) => (
                  <Stack key={i}>
                    <FormControlLabel
                      value={item.radarCoin}
                      control={<Radio />}
                      label={item.name}
                      onChange={() =>
                        handleOpciones(item.amount, item.radarCoin)
                      }
                    />
                    <Typography
                      variant="body1"
                      color="GrayText"
                      fontWeight="400"
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                ))}
              </RadioGroup>
              <LoadingButton
                loading={loadingApi.includes("post__artista/envios")}
                sx={{ px: 4, mt: 2 }}
                onClick={() => sendSong()}
              >
                Enviar canción
              </LoadingButton>
            </FormControl>
          </Box>
        ) : (
          <Box sx={style}>
            <Stack>
              <Stack alignItems="center" spacing={2}>
                <Box width="150px" height="150px">
                  <img
                    src={info.profileimg || "/assets/AVATAR.jpg"}
                    width="100%"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid #F27224",
                    }}
                  />
                </Box>
                {/* info */}
                <Stack>
                  <Stack
                    sx={{
                      "& > legend": { mt: 2 },
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Rating
                      name="read-only"
                      value={info.rating_prom}
                      readOnly
                    />
                  </Stack>
                  <Typography variant="subtitle1" textAlign="center">
                    {info.nombre_media}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    fontWeight="400"
                    color="#F27224"
                    mt="-10px"
                  >
                    {info.tipo}
                  </Typography>
                  <Typography variant="body1" color="grayText" fontWeight="400">
                    {info.descripcion}
                  </Typography>
                  {info.pagina_web && (
                    <Typography variant="subtitle1" fontWeight="400">
                      <strong>Visitame en </strong>
                      <a
                        href={info.pagina_web}
                        target="_blank"
                        rel="nofollow noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "#F27224",
                        }}
                      >
                        Mi pagina web.
                      </a>
                    </Typography>
                  )}
                </Stack>
                {/* Redes sociales*/}
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {socialNetwork.map(({ name, Icon }, i) => (
                    <Button
                      key={i}
                      sx={{
                        py: 1,
                        px: 1,
                        minWidth: "auto",
                        ":hover": {
                          color: "#ffffff",
                          bgcolor: "#F27224",
                        },
                      }}
                      variant="outlined"
                      href={info[name]}
                      target="_blank"
                    >
                      <Icon />
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        )}
      </Modal>
    </>
  );
}
