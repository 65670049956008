import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Grid, Stack } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={handleClickOpen}>
            Open dialog
          </Button>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            {/* PRIMERA SECCION  */}
            <Grid item xs={12}>
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                Datos de envio
              </BootstrapDialogTitle>
            </Grid>

            {/* SEGUNDA SECCION */}
            <Grid item xs={6} sx={{ justifyContent: "space-around" }}>
              <img
                src="/assets/imagenpost.jpg"
                alt="imagen"
                style={{ width: "250px", height: "100px" }}
              />
              <Box>
                <h2>$1</h2>
                <h3>Luis Picazo- "Eras Tu"</h3>
                <p>
                  Fecha de envio: 21/12 /22 Pais: México Género: Indie Rock
                  Distribuido por: Independiente
                </p>
                <Stack direction="row" alignItems="center">
                  <Avatar alt="Remy Sharp" src="/assets/icono.png" />
                  <Avatar alt="Travis Howard" src="/assets/instagram.png" />
                  <Avatar alt="Cindy Baker" src="/assets/spotify.png" />
                  <Avatar alt="Agnes Walker" src="/assets/twiter.jpg" />
                  <Avatar alt="Trevor Henderson" src="/assets/Facebook.png" />
                </Stack>
              </Box>
            </Grid>

            {/* <DialogContent dividers>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent> */}
          </BootstrapDialog>
        </Grid>
      </Grid>
    </Grid>
  );
}
