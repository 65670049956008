import {
  Autocomplete,
  Box,
  Chip,
  Container,
  Divider,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";
import { useUser } from "../../../hooks/useUser";
import styled from "@emotion/styled";
import useApi from "../../../hooks/useApi";
//icons
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import countries from "../../../utils/countries";
import {
  FaSpotify,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaMailBulk,
} from "react-icons/fa";
import generos from "../../../utils/generos";

const initForm = {
  nombre_media: "",
  pais: "",
  descripcion: "",
  pagina_web: "",
  facebook: "",
  instagram: "",
  youtube: "",
  spotify: "",
  tiktok: "",
};

const initFormUser = {
  name: "",
  username: "",
};

const initFormPassword = {
  password: "",
  confirmPassword: "",
};

const tiposArtista = [
  "Solista",
  "Grupo Musical",
  "Sello Discográfico",
  "Manager o RP",
];

const countryOptions = Object.entries(countries).map(([key, value]) => ({
  value: key,
  label: value,
}));

const generoOptions = generos.map((item) => ({
  value: item,
  label: item,
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: 8,
  textTransform: "none",
}));

const EditProfileArtist = () => {
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { user, updateUser } = useUser();
  const { loadApi, loadingApi } = useApi();
  const navigate = useNavigate();
  //States
  const [form, setForm] = useState(initForm);
  const [formUser, setFormUser] = useState(initFormUser);
  const [formPassword, setFormPassword] = useState(initFormPassword);
  const [tags, setTags] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [musicalGenders, setMusicalGenders] = useState(generoOptions);

  const [selectedFiles, setPreviewImage] = useState([]);
  const [bannerImgState, setBannerImgState] = useState(null);

  //UseEffect
  useEffect(() => {
    setTags(user?.profile?.generos_musicales);
    setPreviewImage(user?.profile?.bannerimg);
  }, []);

  //cambiar datos del state
  const handleInputChange = ({ target }, setState) => {
    const { name, value } = target;
    setState((adata) => ({
      ...adata,
      [name]: value,
    }));
  };

  //Cambiar datos del autocomplete
  const handleAutocompleteChange = (e, name) => {
    if (name) setForm((adata) => ({ ...adata, pais: name.value }));
    else setForm((adata) => ({ ...adata, pais: "mx" }));
  };

  //Cambiar información del usuario
  const handleSubmitUser = async (e, oldBody) => {
    e.preventDefault();
    try {
      let body;
      if (Object.keys(oldBody).includes("password")) {
        if (oldBody.password === oldBody.confirmPassword)
          body = { password: oldBody.password };
        else {
          return showSnack("las contraseñas no coinciden", "error");
        }
      } else {
        body = oldBody;
      }
      const { data } = await loadApi("user", true, "put", body);
      showSnack(data.message);
      updateUser();
    } catch (error) {
      showSnack(error.message, "error");
    }
  };

  const handleChangeImg = async ({ target }) => {
    const [file] = target.files;
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setBannerImgState(file);
    }
  };

  const submitImage = async ({ target }) => {
    const file = bannerImgState;
    if(file == null) return;
    if (file) {
      try {
        const { data } = await loadApi(
          "artista/profile/bannerimg",
          true,
          "put",
          { bannerimg: file },
          true
        );

        showSnack(data.message);
        updateUser();
        setBannerImgState(null);
      } catch (error) {
        showSnack(error.message, "error");
      }
    } else {
      setPreviewImage(null);
    }
  };

  //Cambiar información publica
  const handleSubmitProfile = async (e) => {
    e.preventDefault();
    const body = form;
    if (body.paypal_email) body.paypal_email = body.paypal_email.toLowerCase();
    if (tags.length > 0) body["generos_musicales"] = tags;
    try {
      const { data } = await loadApi(
        "artista/profile",
        true,
        "put",
        body,
        true
      );
      showSnack(data.message);
      updateUser();
    } catch (error) {
      showSnack(error.message, "error");
    }
  };

  return (
    <Stack bgcolor="#f0f1f7" minHeight="calc(100vh - 40px)" pb={5}>
      <GlobalHeader />
      <Container maxWidth="md">
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <Typography variant="h6" color="#3b3b3b">
            Editar perfil
          </Typography>
        </Stack>
        {/* Edit cart */}
        <Stack
          bgcolor="#ffffff"
          sx={{
            borderRadius: "20px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          py={4}
          px={{ xs: 1, sm: 3 }}
        >
          {/* User info */}
          <Typography variant="subtitle1" color="#3b3b3b">
            Información de usuario
          </Typography>
          <Divider />
          <Stack
            component="form"
            role="form"
            gap={2}
            py={2}
            onSubmit={(e) => handleSubmitUser(e, formUser)}
          >
            <TextField
              size="small"
              name="name"
              type="text"
              label="Nombre cuenta"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
              onChange={(e) => handleInputChange(e, setFormUser)}
              defaultValue={user?.name}
              sx={{
                maxWidth: "400px",
              }}
            />
            <TextField
              size="small"
              name="username"
              type="email"
              label="Correo electronico"
              onChange={(e) => handleInputChange(e, setFormUser)}
              defaultValue={user?.username}
              sx={{
                maxWidth: "400px",
              }}
            />
            <Stack alignItems="end">
              <StyledButton
                loading={loadingApi.includes("put__user")}
                loadingPosition="start"
                variant="outlined"
                startIcon={<SaveOutlinedIcon />}
                type="submit"
              >
                Guardar
              </StyledButton>
            </Stack>
          </Stack>
          {/* Profile info */}
          <Typography variant="subtitle1" color="#3b3b3b">
            Información publica
          </Typography>
          <Divider />
          <Stack
            component="form"
            role="form"
            gap={2}
            py={2}
            onSubmit={handleSubmitProfile}
          >
            <TextField
              size="small"
              name="nombre_media"
              type="text"
              label="Nombre"
              onChange={(e) => handleInputChange(e, setForm)}
              defaultValue={user?.profile?.nombre_media}
              sx={{
                maxWidth: "400px",
              }}
            />
            <Stack direction="row" gap={1} width="100%" maxWidth="400px">
              <Stack
                justifyContent="center"
                p={1}
                border=".5px solid #00000040"
                borderRadius={1}
              >
                <Box
                  component="img"
                  src={`https://flagcdn.com/${
                    form.pais || user?.profile?.pais
                  }.svg`}
                  alt="País"
                  width="30px"
                  height="20px"
                />
              </Stack>
              <Autocomplete
                disablePortal
                disableClearable
                defaultValue={countries[user?.profile?.pais]}
                onChange={handleAutocompleteChange}
                options={countryOptions}
                name="pais"
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Stack>
            <TextField
              size="small"
              name="descripcion"
              type="text"
              label="Descripcion"
              multiline={true}
              rows={7}
              onChange={(e) => handleInputChange(e, setForm)}
              defaultValue={user?.profile?.descripcion}
              sx={{
                maxWidth: "400px",
              }}
            />
            <Stack maxWidth="400px">
              <FormLabel sx={{ color: "grayText" }}>Tipo de artista</FormLabel>
              <Select
                defaultValue={user?.profile?.tipo}
                onChange={(e) => handleInputChange(e, setForm)}
                name="tipo"
                sx={{ fontWeight: "400" }}
                size="small"
              >
                {tiposArtista.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {/* Social network */}
            <Stack maxWidth="400px" gap={3}>
              <FormLabel sx={{ color: "grayText", fontWeight: 600 }}>
                Redes Sociales y contacto:
              </FormLabel>
              <Stack gap={2}>
                <TextField
                  size="small"
                  name="email_contacto"
                  type="text"
                  label="Email de contacto"
                  onChange={(e) => handleInputChange(e, setForm)}
                  defaultValue={user?.profile?.email_contacto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaMailBulk style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  name="facebook"
                  type="text"
                  label="Cuenta de facebook"
                  onChange={(e) => handleInputChange(e, setForm)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaFacebook style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={user?.profile?.facebook}
                />
                <TextField
                  size="small"
                  name="instagram"
                  type="text"
                  label="Cuenta de instagram"
                  onChange={(e) => handleInputChange(e, setForm)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaInstagram style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={user?.profile?.instagram}
                />
                <TextField
                  size="small"
                  name="youtube"
                  type="text"
                  label="Cuenta de youtube"
                  onChange={(e) => handleInputChange(e, setForm)}
                  defaultValue={user?.profile?.youtube}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaYoutube style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  name="spotify"
                  type="text"
                  label="Cuenta de spotify"
                  onChange={(e) => handleInputChange(e, setForm)}
                  defaultValue={user?.profile?.spotify}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaSpotify style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  name="tiktok"
                  type="text"
                  label="Cuenta de tiktok"
                  onChange={(e) => handleInputChange(e, setForm)}
                  defaultValue={user?.profile?.tiktok}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaTiktok style={{ color: "#F27224" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            {/* Links and payments */}
            <Stack maxWidth="400px">
              <TextField
                size="small"
                name="pagina_web"
                type="text"
                label="Pagina web"
                onChange={(e) => handleInputChange(e, setForm)}
                defaultValue={user?.profile?.pagina_web}
              />
            </Stack>
            <Stack alignItems="end">
              <StyledButton
                loading={loadingApi.includes("put__artista/profile")}
                loadingPosition="start"
                variant="outlined"
                startIcon={<SaveOutlinedIcon />}
                type="submit"
              >
                Guardar
              </StyledButton>
            </Stack>
          </Stack>
          {/* Change password */}
          <Typography variant="subtitle1" color="#3b3b3b">
            Cambiar contraseña
          </Typography>
          <Divider />
          <Stack
            component="form"
            role="form"
            gap={2}
            py={2}
            onSubmit={(e) => handleSubmitUser(e, formPassword)}
          >
            <TextField
              size="small"
              name="password"
              type="password"
              label="Nueva contraseña"
              onChange={(e) => handleInputChange(e, setFormPassword)}
              sx={{
                maxWidth: "400px",
              }}
            />
            <TextField
              size="small"
              name="confirmPassword"
              type="password"
              label="Confirmar nueva contraseña"
              onChange={(e) => handleInputChange(e, setFormPassword)}
              sx={{
                maxWidth: "400px",
              }}
            />
            <Stack alignItems="end">
              <StyledButton
                loading={loadingApi.includes("put__user")}
                loadingPosition="start"
                variant="outlined"
                startIcon={<SaveOutlinedIcon />}
                type="submit"
              >
                Guardar
              </StyledButton>
            </Stack>
          </Stack>

              
         {/* Change Banner img */}

         <Typography variant="subtitle1" color="#3b3b3b">
                Cambiar Banner
              </Typography>
              <Divider />
              
          <Stack
            direction="row"
            justifyContent="start"
            gap={4}
            py = {3}
          >
            <LoadingButton
              component="label"
              variant="outlined"
              sx={{
                backgroundImage: `url(${selectedFiles})`,
                borderRadius: "4px",
                backgroundColor: "white",
                backgroundSize: "100% 100%",
                width: "300px!important",
                height: "150px!important",
              }}
            >
              {!selectedFiles && "+"}
              <input
                type="file"
                id="fileUpload"
                onChange={handleChangeImg}
                style={{ width: "100%", height: "100%" }}
                hidden
                className="inputFileInput"
                accept="image/*"
              />
            </LoadingButton>

          </Stack>
          <Stack alignItems="end">
                  <StyledButton
                    loadingPosition="start"
                    variant="outlined"
                    startIcon={<SaveOutlinedIcon />}
                    onClick={submitImage}
                    type="submit"
                  >
                    Guardar
                  </StyledButton>
            </Stack>


        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </Stack>
  );
};

export default EditProfileArtist;
