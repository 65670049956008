import { Container, Stack, TextField, Typography } from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader/index";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useApi from "../../../hooks/useApi";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { useUser } from "../../../hooks/useUser";

const Vouchers = () => {
  const [code_voucher, setCode_voucher] = useState("");
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const { updateUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!code_voucher) {
      showSnack("El bono es requerido!", "warning");
      return;
    }
    try {
      const { data } = await loadApi("artista/bonos", true, "post", {
        code_voucher: code_voucher.trim().toUpperCase(),
      });
      showSnack(data.message, "success");
      updateUser();
      setCode_voucher("");
    } catch (error) {
      showSnack(error.message, "warning");
    }
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack spacing={3}>
          <Typography variant="h4">Bono de regalo radar coin</Typography>
          <Stack
            component="form"
            role="form"
            bgcolor="#f1f1f1"
            spacing={3}
            p={4}
            borderRadius="10px"
            width="100%"
            maxWidth="600px"
            onSubmit={handleSubmit}
          >
            <TextField
              variant="standard"
              label="Ingresa tu codigo de bono"
              value={code_voucher}
              onChange={({ target }) => setCode_voucher(target.value)}
            />
            <Stack alignItems="flex-end">
              <LoadingButton
                loading={loadingApi.includes("post__artista/bonos")}
                sx={{ padding: "7px 30px" }}
                type="submit"
              >
                Enviar
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Vouchers;
