const styles = {
  root: {
    height: {
      xs: "calc(100vh - 72px)",
      lg: "100vh",
    },
  },
  barramovil: {
    display: {
      xs: "flex",
      lg: "none",
    },
    img: {
      height: "40px",
    },
    backgroundColor: "primary.main",
    padding: 2,
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: {
      lg: "flex",
      xs: "none",
    },
    justifyContent: "center",
    alignItems: "start",
    pt: 5,
  },
  title: {
    fontSize: "250%",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#f27d02",
    borderRadius: "30px",
    fontSize: "1.1rem",
    paddingY: 1,
    paddingX: 4,
    "&:hover": {
      backgroundColor: "#db7000",
    },
  },
};

export default styles;
