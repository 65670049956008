import React, { useEffect, useState } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import { Box, Container, Stack, Typography } from "@mui/material";
import useApi from "../../hooks/useApi";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Faqs = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const { loadApi } = useApi()
  const [ faqs, setFaqs ] = useState("")
  const [ terminos, setTerminos ] = useState("")
  //Traer mensaje de compartir
  useEffect(() => {
    loadApi("general/parametros", false, "get").then((res) => {
      setFaqs(res.data.faqs);
      setTerminos(res.data.terminos);
    });
  }, []);

  return (
    <>
      <GlobalHeader />
      {/* <Container sx={{ pb: 4, pt: 4 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          <Box dangerouslySetInnerHTML={{ __html: faqs }} />
          <pre
            style={{
              fontFamily: "'Roboto', Helvetica, Arial",
              fontWeight: "400",
              wordWrap: "break-word",
              textWrap: "wrap"
            }}
          >
          </pre>
        </Typography>
        
      </Container> */}

      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="FAQs" {...a11yProps(0)} />
          <Tab label="T&Cs" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box dangerouslySetInnerHTML={{ __html: faqs }} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Box dangerouslySetInnerHTML={{ __html: terminos }} />
      </CustomTabPanel>
    </Box>
    </>
  );
};

export default Faqs;
