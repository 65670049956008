import { Container, Grid, Box, Typography, Stack, Button } from "@mui/material";
import { Scrollama, Step } from "react-scrollama";
import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";

import youtubeToEmbed from "../../../utils/youtubeToEmbed";
import countries from "../../../utils/countries";
import spotifyToEmbed from "../../../utils/spotifyToEmbed";

import {
  FaSpotify,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaFacebook,
} from "react-icons/fa";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import { verifyPlan } from "../../../utils/plan";
import { getDateAndHour, getTime } from "../../../utils/date";
import { Globals } from "../../../utils/environtments";

const socialNetwork = [
  { name: "facebook", Icon: FaFacebook },
  { name: "instagram", Icon: FaInstagram },
  { name: "youtube", Icon: FaYoutube },
  { name: "spotify", Icon: FaSpotify },
  { name: "tiktok", Icon: FaTiktok },
];

const CancionPc = ({ mainData, loadingApi, updateSend, showSnack }) => {
  //datos del scrollama
  const [currentStepIndex, setCurrentStepIndex] = useState(mainData[0]);
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };
  //useEfect inicio
  useEffect(() => {
    adjustIframe();
    window.addEventListener("resize", adjustIframe);
    return () => {
      window.removeEventListener("resize", adjustIframe);
    };
  }, []);

  //funciones de embed

  function adjustIframe() {
    const item = window.document.querySelector(
      "#iframePrincipal" + currentStepIndex.id
    );
    item.style.height = (item.offsetWidth * 9) / 16 + "px";
  }

  function obtenerEmbed() {
    const item = window.document.querySelector(
      "#iframePrincipal" + currentStepIndex.id
    );
    const nuevoEmbed = srcCodigoEmbed(
      item.innerHTML,
      `${Globals.FRONT_URL}/embed/${currentStepIndex.id}`
    );
    navigator.clipboard
      .writeText(`${nuevoEmbed}`)
      .then(() => {
        showSnack("Texto copiado al portapapeles");
      })
      .catch((err) => {
        showSnack("Error al copiar el texto: ", "error");
      });
  }

  const srcCodigoEmbed = (codigoEmbed, nuevoSrc) => {
    // Buscar el inicio de la propiedad src
    const inicioSrc = codigoEmbed.indexOf('src="');
    if (inicioSrc === -1) {
      // La propiedad src no se encontró
      return codigoEmbed;
    }

    // Buscar el final de la propiedad src
    const finSrc = codigoEmbed.indexOf('"', inicioSrc + 5);
    if (finSrc === -1) {
      // No se encontró el cierre de las comillas de la propiedad src
      return codigoEmbed;
    }

    // Extraer la parte del código antes y después de la propiedad src
    const codigoAntesSrc = codigoEmbed.substring(0, inicioSrc + 5);
    const codigoDespuesSrc = codigoEmbed.substring(finSrc);

    // Construir el nuevo código con el src modificado
    const nuevoCodigoEmbed = codigoAntesSrc + nuevoSrc + codigoDespuesSrc;

    return nuevoCodigoEmbed;
  };

  return (
    <>
      <Container>
        <Stack direction="row" spacing={2} mb={5}>
          {/* COLUMNA IZQUIERDA */}
          <Stack sx={{ width: "40%" }}>
            <Scrollama offset={0.5} onStepEnter={onStepEnter}>
              {mainData.map((item, i) => (
                <Step data={item} key={i}>
                  <Stack
                    spacing={2}
                    mb={4}
                    sx={{
                      opacity: currentStepIndex.id === item.id ? 1 : 0.5,
                    }}
                  >
                    <Typography
                      noWrap
                      variant="h4"
                      sx={{
                        paddingBottom: 1,
                        borderBottom: "2px solid gray",
                      }}
                    >
                      {item.nombre}
                    </Typography>
                    <Stack>
                      <Box>
                        <Box
                          sx={{ width: { xs: "300px" } }}
                          display="flex"
                          justifyContent="center"
                        >
                          <img
                            src={item.url_img}
                            style={{ borderRadius: "4px/6.7px" }}
                            width="100%"
                          />
                        </Box>
                      </Box>
                      <Stack>
                        <Stack spacing={1}>
                          <Typography variant="h6">
                            {item.profile.nombre_media}
                          </Typography>
                          <Typography variant="p">
                            Fecha de envio: {getDateAndHour(item.fecha)}
                          </Typography>
                          <Typography variant="p">
                            Tiempo restante:{" "}
                            <Typography
                              variant="p"
                              color="red"
                              fontWeight={700}
                            >
                              {getTime(item.fecha)}
                            </Typography>
                          </Typography>
                          <Typography variant="p">
                            País: {countries[item.profile.pais]}
                          </Typography>
                          <Typography variant="p">
                            Género: {item.genero}
                          </Typography>
                          {/* redes sociales */}
                          <Typography variant="subtitle1">
                            Redes sociales:
                          </Typography>
                          <Stack direction="row" flexWrap="wrap" gap={1}>
                            {socialNetwork.map(({ name, Icon }, i) => (
                              <Button
                                key={i}
                                sx={{
                                  py: 1,
                                  px: 1,
                                  minWidth: "auto",
                                  ":hover": {
                                    color: "#ffffff",
                                    bgcolor: "#F27224",
                                  },
                                }}
                                variant="outlined"
                                href={item[name]}
                                target="_blank"
                              >
                                <Icon />
                              </Button>
                            ))}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Step>
              ))}
            </Scrollama>
          </Stack>
          {/* COLUMNA DERECHA */}
          <Stack sx={{ width: "60%" }}>
            <Stack
              spacing={2}
              px={3}
              sx={{
                position: "sticky",
                top: "0",
                pt: 2,
              }}
            >
              <Stack alignItems="center">
                {youtubeToEmbed(currentStepIndex.url) ? (
                  <Box
                    id={`iframePrincipal${currentStepIndex.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={youtubeToEmbed(currentStepIndex.url)}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : spotifyToEmbed(currentStepIndex.url) ? (
                  <Box
                    id={`iframePrincipal${currentStepIndex.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={spotifyToEmbed(currentStepIndex.url)}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                ) : (
                  <Box
                    id={`iframePrincipal${currentStepIndex.id}`}
                    width="100%"
                    sx={{ iframe: { borderRadius: "4px/6.7px" } }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      style={{ maxWidth: "560px", maxHeight: "340px" }}
                      src={currentStepIndex.url}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      alowFullScreen
                    ></iframe>
                  </Box>
                )}
              </Stack>
              <Stack
                direction="row"
                spacing={1.3}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" align="left">
                  Obtener codigo embed
                </Typography>
                <Button color="info" onClick={obtenerEmbed}>
                  Copiar
                </Button>
              </Stack>
              <Typography
                variant="h5"
                color="primary"
                sx={{
                  borderBottom: "2px dashed gray",
                }}
              ></Typography>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <LoadingButton
                  onClick={() =>
                    updateSend(
                      "approve",
                      currentStepIndex.id,
                      currentStepIndex.url
                    )
                  }
                  color="success"
                  sx={{ textTransform: "none" }}
                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                >
                  Aprobar
                </LoadingButton>
                <LoadingButton
                  onClick={() =>
                    updateSend(
                      "rejected",
                      currentStepIndex.id,
                      currentStepIndex.url
                    )
                  }
                  color="error"
                  sx={{ textTransform: "none" }}
                  startIcon={<DoDisturbAltOutlinedIcon />}
                >
                  Rechazar
                </LoadingButton>
              </Stack>
              {/* descripccion del plan */}
              <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6" fontWeight="600">
                    ${currentStepIndex.amount}USD -{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="red"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {verifyPlan(currentStepIndex.amount).fullname}
                  </Typography>
                </Stack>
                <Typography variant="subtitle1" fontWeight="500">
                  {verifyPlan(currentStepIndex.amount).description}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="h5">Descripción de la canción</Typography>
                <Typography variant="p">
                  <pre
                    style={{
                      fontFamily: "'Roboto', Helvetica, Arial",
                      fontWeight: "400",
                    }}
                  >
                    {currentStepIndex.descripcion}
                  </pre>
                </Typography>
                <Typography variant="h5">Perfil</Typography>
                <Typography variant="p">
                  {currentStepIndex.profile.descripcion}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default CancionPc;
