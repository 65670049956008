import styled from "@emotion/styled";
import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  Modal,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import { VscMail } from "react-icons/vsc";
import { Send } from "@mui/icons-material";
import GlobalHeader from "../../../components/GlobalHeader";
import { useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { useState } from "react";
import Audio from "react-loading-icons/dist/esm/components/audio";
import { LoadingButton } from "@mui/lab";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    padding: "5px 0",
  },
}));

//style modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const UsersAdmin = () => {
  const navigate = useNavigate();
  const { loadApi, loadingApi } = useApi();
  const [productores, setProductores] = useState([]);
  const [artistas, setArtistas] = useState([]);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const { showSnack, openSB, typeSB, msgSB } = useSnack();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchProductores, setSearchProductores] = useState();
  const [searchArtistas, setSearchArtistas] = useState();
  const [product, setProduct] = useState(false);
  const [artist, setArtist] = useState(false);

  //load api productores
  useEffect(() => {
    loadApi("admin/profiles/productores", true, "get")
      .then((res) => {
        setProductores(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [product]);

  //load api artistas
  useEffect(() => {
    loadApi("admin/profiles/artistas", true, "get")
      .then((res) => {
        setArtistas(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [artist]);

  //cerrar
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    loadApi("admin/correo", true, "post", data)
      .then((res) => {
        showSnack(res.data.message, "success");
        setOpen(false);
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  };

  //search productores
  const handleSearch = (value) => {
    clearTimeout(typingTimeout);
    setSearchProductores(value);

    if (value) {
      const newTypingTimeout = setTimeout(() => {
        const filteredPeople = productores.filter((person) =>
          person.profile?.nombre_media
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setProductores(filteredPeople);
      }, 1000);

      setTypingTimeout(newTypingTimeout);
    } else setProduct(!product);
  };

  //search artistas
  const handleSearchArt = (value) => {
    clearTimeout(typingTimeout);
    setSearchProductores(value);

    if (value) {
      const newTypingTimeout = setTimeout(() => {
        const filteredPeople = artistas.filter((person) =>
          person.profile?.nombre_media
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setArtistas(filteredPeople);
      }, 1000);

      setTypingTimeout(newTypingTimeout);
    } else setArtist(!artist);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack spacing={1}>
            <Typography variant="h6" textAlign="center">
              Enviar correo
            </Typography>
            <Stack spacing={2}>
              <TextField
                type="email"
                label="Para"
                value={data.to}
                variant="filled"
                onChange={(e) =>
                  setData((adata) => ({
                    ...adata,
                    to: e.target.value.toLowerCase(),
                  }))
                }
              ></TextField>
              <TextField
                type="text"
                label="Asunto"
                variant="filled"
                onChange={(e) =>
                  setData((adata) => ({ ...adata, subject: e.target.value }))
                }
              ></TextField>
              <TextField
                multiline
                type="text"
                rows={6}
                label="descripcion"
                onChange={(e) =>
                  setData((adata) => ({
                    ...adata,
                    description: e.target.value,
                  }))
                }
              ></TextField>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                loading={loadingApi.includes("post__admin/correo")}
                onClick={handleSubmit}
                endIcon={<Send />}
                sx={{ px: "24px" }}
              >
                Enviar
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <GlobalHeader />
      <Container>
        <Stack pb={6}>
          <Typography variant="h4">Curadores</Typography>
          <Stack alignItems="end" sx={{ gap: "20px" }}>
            <TextField
              variant="outlined"
              placeholder="Buscar por nombre media"
              onChange={(e) => handleSearch(e.target.value)}
            ></TextField>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell> ID </StyledTableCell>
                    <StyledTableCell> Nombre </StyledTableCell>
                    <StyledTableCell> Nombre del medio </StyledTableCell>
                    <StyledTableCell> Email </StyledTableCell>
                    <StyledTableCell> Tipo de curador </StyledTableCell>
                    <StyledTableCell> RadarCoins (USD) </StyledTableCell>
                    <StyledTableCell> Acciones </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingApi.includes("get__admin/profiles/productores") &&
                    productores.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={7}>
                          <Audio fill="#F27224" />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}

                  {!loadingApi.includes("get__admin/profiles/productores") &&
                  productores.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7}>
                        <Typography variant="h5">No hay resultados</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    productores.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{item.id}</StyledTableCell>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>
                          {item.profile.nombre_media}
                        </StyledTableCell>
                        <StyledTableCell>{item.username}</StyledTableCell>
                        <StyledTableCell>{item.profile.tipo}</StyledTableCell>
                        <StyledTableCell>
                          ${item.profile.credits}USD
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() =>
                              navigate(`/admin/users/canciones/${item.id}`)
                            }
                          >
                            <LibraryMusicIcon />
                          </Button>
                          <Button
                            onClick={() => {
                              setData({ to: item.username, userId: item.id });
                              setOpen(true);
                            }}
                            sx={{ fontSize: "26px" }}
                          >
                            <VscMail />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
        <Stack pb={6}>
          <Typography variant="h4">Musicos</Typography>
          <Stack alignItems="end" sx={{ gap: "20px" }}>
            <TextField
              variant="outlined"
              placeholder="Buscar por nombre media"
              onChange={(e) => handleSearchArt(e.target.value)}
            ></TextField>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell> ID </StyledTableCell>
                    <StyledTableCell> Nombre </StyledTableCell>
                    <StyledTableCell> Email </StyledTableCell>
                    <StyledTableCell> Email de contacto </StyledTableCell>
                    <StyledTableCell> Nombre encargado </StyledTableCell>
                    <StyledTableCell> Nombre media </StyledTableCell>
                    <StyledTableCell> Tipo </StyledTableCell>
                    <StyledTableCell> RadarCoins </StyledTableCell>
                    <StyledTableCell> Acciones </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingApi.includes("get__admin/profiles/artistas") &&
                    artistas.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={9}>
                          <Audio fill="#F27224" />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  {!loadingApi.includes("get__admin/profiles/artistas") &&
                  artistas.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Typography variant="h5">No hay resultados</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    artistas.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{item.id}</StyledTableCell>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>{item.username}</StyledTableCell>
                        <StyledTableCell>
                          {item.profile.email_contacto}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.profile.nombre_encargado}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.profile.nombre_media}
                        </StyledTableCell>
                        <StyledTableCell>{item.profile.tipo}</StyledTableCell>
                        <StyledTableCell>
                          {item.profile.credits}RC
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            onClick={() => {
                              setData({ to: item.username, userId: item.id });
                              setOpen(true);
                            }}
                            sx={{ fontSize: "26px" }}
                          >
                            <VscMail />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default UsersAdmin;
