const handleInput = (name, setData) => (event, value2, reason, details) => {
  var value = event.target.value;
  if (reason === "selectOption") {
    value = details.option.value;
  }
  if (event.target.type === "checkbox") {
    value = value2;
  }
  if (event.target.type === "file") {
    value = event.target.files[0];
  }
  if (value) {
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  } else {
    setData((data) => {
      const newData = { ...data };
      delete newData[name];
      return newData;
    });
  }
};

export default handleInput;
