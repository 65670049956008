import {
  Button,
  MenuItem,
  Select,
  Stack,
  Chip,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useUser } from "../../hooks/useUser";
import { isURL } from "validator";
import { useState } from "react";

export default function Step1({
  changeForm,
  mainData,
  showSnack,
  tags,
  setTags,
}) {
  const [inputValue, setInputValue] = useState("");
  const { user } = useUser();

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAdd = () => {
    if (!isURL(inputValue)) {
      showSnack(
        "El link que ingresaste de tu red social no es válido!",
        "warning"
      );
      return;
    }

    const updatedInputValue =
      !inputValue.startsWith("http://") && !inputValue.startsWith("https://")
        ? `https://${inputValue}`
        : inputValue;

    const url = new URL(updatedInputValue);
    setTags((prevTags) => [
      ...prevTags,
      {
        host: url.hostname.replace("www.", "") + url.pathname,
        full: inputValue,
      },
    ]);

    setInputValue("");
  };

  const handleDelete = (tagToDelete) => () => {
    setTags((prevTags) => prevTags.filter((tag) => tag.full !== tagToDelete));
  };

  return (
    <Stack alignItems="center">
      <Stack
        spacing={2.3}
        width={{ xs: "100%", sm: "90%", md: "70%", lg: "50%" }}
      >
        <Stack spacing={1}>
          <Typography variant="p">Ingresa tu email de contacto:</Typography>
          <TextField
            value={mainData?.email_contacto || ""}
            onChange={changeForm("email_contacto")}
            fullWidth
          ></TextField>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="p">Ingresa tu página web:</Typography>
          <TextField
            value={mainData?.pagina_web || ""}
            onChange={changeForm("pagina_web")}
            fullWidth
          ></TextField>
        </Stack>
        {/* <Stack spacing={1}>
          <Typography variant="p">Ingresa tus redes sociales:</Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <TextField
              placeholder="instagram.com/radarfreim"
              fullWidth
              value={inputValue}
              onChange={handleChange}
            />
            <Button onClick={handleAdd}>Agregar</Button>
          </Stack>
          <Stack direction="row" gap={1} marginTop={1} flexWrap="wrap">
            {tags.map((tag, i) => (
              <Chip
                key={i}
                label={tag.host}
                onDelete={handleDelete(tag.full)}
              />
            ))}
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
}
