export const Globals = {
  AUTH_ENDPOINT: "https://accounts.spotify.com/authorize",
  RESPONSE_TYPE: "token",
  //Credenciales local
  // FRONT_URL: "http://localhost:3000",
  // FRONT_URL: "https://radar.ikoodi.site",
  // SPOTIFY_CLIENT_ID: "3ce42a0bd90c4a1ea76fa5cf11fe5bbc",
  // REDIRECT_URI: "http://localhost:3000/productor/envios",
  // REDIRECT_URI: "https://radar.ikoodi.site/productor/envios",
  //client id del servidor radar.freim.tv
  SPOTIFY_CLIENT_ID: "37351a4547824cef8d6557357e4cab51",
  FRONT_URL: "https://radar.freim.tv",
  REDIRECT_URI: "https://radar.freim.tv/productor/envios",
};
