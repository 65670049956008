import {
  Modal,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  Badge,
} from "@mui/material";
import { useState } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import SnackAlert from "../SnackAlert";
import { getDateAndHour } from "../../utils/date";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: 400,
  height: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  width: "100vw",
  maxWidth: { xs: 300, sm: 500 },
  "::-webkit-scrollbar": {
    display: "none",
  },
};

const Notificaciones = ({ noti, setNoti }) => {
  const [open, setOpen] = useState(false);
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const updateNotify = (id) => {
    loadApi(`notificaciones/${id}`, true, "put", {})
      .then((res) => {
        showSnack(res.data.message, "success");
      })
      .finally(() => {
        loadApi("notificaciones", true, "get").then((response) => {
          setNoti(response.data);
        });
      });
  };

  return (
    <>
      <Stack justifyContent="center">
        <IconButton onClick={handleOpen} color="secondary">
          <Badge
            color="tertiary"
            invisible={noti.length === 0}
            badgeContent={noti.length}
            max={10}
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
      </Stack>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Stack>
            <Typography variant="subtitle1">Notificaciones</Typography>
            <Box
              sx={{
                width: "100%",
                background: "grayText",
                height: "2px",
                opacity: 0.3,
              }}
            ></Box>
            <Stack sx={{ gap: "10px", mt: 2 }}>
              {noti.length === 0 ? (
                <Stack
                  alignItems="center"
                  height="300px"
                  justifyContent="center"
                >
                  <Typography variant="body1" textAlign="center">
                    ¡No hay notificaciones!
                  </Typography>
                </Stack>
              ) : (
                noti.map(
                  (item, i) =>
                    !item.checked && (
                      <Stack
                        key={i}
                        sx={{
                          p: "10px",
                          borderRadius: "20px",
                          cursor: "pointer",
                          ":hover": {
                            transform: "scale(1.02)",
                            transition: ".1s all linear",
                          },
                        }}
                        backgroundColor="#f1f1f1"
                        onClick={() => updateNotify(item.id)}
                      >
                        <Typography variant="body1" color="GrayText">
                          {item.description}
                        </Typography>
                        <Stack direction="row" justifyContent="flex-end">
                          <Typography
                            variant="body2"
                            fontWeight={400}
                            color="GrayText"
                          >
                            {getDateAndHour(item.createdAt)}
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                )
              )}
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default Notificaciones;
