import {
  Box,
  Container,
  Stack,
  Typography,
  BottomNavigationAction,
  BottomNavigation,
} from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { GiLoveSong } from "react-icons/gi";
import { GiGuitarBassHead } from "react-icons/gi";
import { BsCashStack } from "react-icons/bs";
import { ImFileExcel } from "react-icons/im";


import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const { loadApi, loadingApi } = useApi([]);
  const [data, setData] = useState({});
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [musicos, setMusicos] = useState({});
  const [productoresNuevos, setProductoresNuevos] = useState({});
  const [canciones, setCanciones] = useState({});
  const [cancionesR, setCancionesR] = useState({});
  const [compras, setCompras] = useState({});
  const [productores, setProductores] = useState({});
  const [artistas, setArtistas] = useState({});
  const [chartO, setChartO] = useState({
    labels: [],
    datasets: [
      {
        label: "Envíos totales",
        data: [],
        backgroundColor: ["#F27224", "#F2C324", "#245DF2", "#24F2C3", "#F2245D"],
        borderColor: "white",
        borderWidth: 1,
      },
    ],
  });
  const [chart2, setChart2] = useState({
    labels: [],
    datasets: [
      {
        label: "Envíos totales",
        data: [],
        backgroundColor: ["#F27224", "#F2C324", "#245DF2", "#24F2C3", "#F2245D"],
        borderColor: "white",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    getMusicosNuevos();
    getProductoresNuevos();
    getCancionesCompartidas();
    getCancionesRechazadas();
    getCompras();
    getTopArtistas();
    getTopProductores();
  }, []);

  //descargaReporte
  const descargarReporte = () => {
    loadApi("admin/stats/downloadReportA", true, "get", undefined, false, true)
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `report.xlsx`,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  //musicos nuevos
  const getMusicosNuevos = () => {
    loadApi("admin/stats/musicos", true, "get").then((res) => {
      setMusicos(res.data);
    });
  };

    //Productores nuevos
   const getProductoresNuevos = () => {
      loadApi("admin/stats/productores", true, "get").then((res) => {
        setProductoresNuevos(res.data);
      });
    };

  //canciones compartidas
  const getCancionesCompartidas = () => {
    loadApi("admin/stats/canciones_compartidas", true, "get").then((res) => {
      setCanciones(res.data);
    });
  };

  //canciones rechazadas
  const getCancionesRechazadas = () => {
    loadApi("admin/stats/canciones_rechazadas", true, "get").then((res) => {
      setCancionesR(res.data);
    });
  };

  //compras
  const getCompras = () => {
    loadApi("admin/stats/compras_creditos", true, "get").then((res) => {
      setCompras(res.data);
    });
  };

  //productores mas activos que comparten
  const getTopProductores = () => {
    loadApi("admin/stats/productores_activos", true, "get").then((res) => {
      setProductores(res.data);
      const { productores_activos_dia } = res.data;
      const arrayNames = productores_activos_dia.map((item) => item.name);
      const arrayValues = productores_activos_dia.map((item) => item.envios);
      setChart2({
        ...chart2,
        labels: arrayNames,
        datasets: [{ ...chart2.datasets[0], data: arrayValues }],
      });
    });
  };

  //artistas mas activos
  const getTopArtistas = () => {
    loadApi("admin/stats/artistas_activos", true, "get").then((res) => {
      const { artistas_activos_dia } = res.data;
      setArtistas(res.data);
      const arrayNames = artistas_activos_dia.map((item) => item.name);
      const arrayValues = artistas_activos_dia.map((item) => item.envios);
      setChartO({
        ...chartO,
        labels: arrayNames,
        datasets: [{ ...chartO.datasets[0], data: arrayValues }],
      });
    });
  };

  const handleChangeTimeProducotres = () => {
    let arraynew = [];
    if (value1 === 1) {
      const { productores_activos_semana } = productores;
      arraynew = productores_activos_semana;
    } else if (value1 === 2) {
      const { productores_activos_mes } = productores;
      arraynew = productores_activos_mes;
    } else {
      const { productores_activos_dia } = productores;
      arraynew = productores_activos_dia;
    }
    const arrayNames = arraynew.map((item) => item.name);
    const arrayValues = arraynew.map((item) => item.envios);
    setChart2({
      ...chart2,
      labels: arrayNames,
      datasets: [{ ...chart2.datasets[0], data: arrayValues }],
    });
  };

  const handleChangeTimeArtist = () => {
    let arraynew = [];
    if (value === 1) {
      const { artistas_activos_semana } = artistas;
      arraynew = artistas_activos_semana;
    } else if (value === 2) {
      const { artistas_activos_mes } = artistas;
      arraynew = artistas_activos_mes;
    } else {
      const { artistas_activos_dia } = artistas;
      arraynew = artistas_activos_dia;
    }
    const arrayNames = arraynew.map((item) => item.name);
    const arrayValues = arraynew.map((item) => item.envios);
    setChartO({
      ...chart2,
      labels: arrayNames,
      datasets: [{ ...chart2.datasets[0], data: arrayValues }],
    });
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          pb={4}
          spacing={1}
        >
          {/* Stats */}
          <Stack flexGrow="1" spacing={2}>
            {/* musicos */}
            <Stack>
              <Typography>Musicos nuevos</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* cards musicos */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos hoy</Typography>
                  <Typography>{musicos.musicos_nuevos_dia || 0}</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos esta semana</Typography>
                  <Typography>{musicos.musicos_nuevos_semana || 0}</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos este mes</Typography>
                  <Typography>{musicos.musicos_nuevos_mes || 0}</Typography>
                </Stack>
              </Stack>
            </Stack>

            {/* productores nuevos */}
            <Stack>
              <Typography>Curadores nuevos</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* cards productores nuevos */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos hoy</Typography>
                  <Typography>{productoresNuevos.productores_nuevos_dia || 0}</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos esta semana</Typography>
                  <Typography>{productoresNuevos.productores_nuevos_semana || 0}</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <FaUsers />
                  </Typography>
                  <Typography textAlign="center">Nuevos este mes</Typography>
                  <Typography>{productoresNuevos.productores_nuevos_mes || 0}</Typography>
                </Stack>
              </Stack>
            </Stack>





            {/* canciones compartidas */}
            <Stack>
              <Typography>Canciones compartidas</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* cards musicos */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiLoveSong />
                  </Typography>
                  <Typography textAlign="center">canciones hoy</Typography>
                  <Typography>
                    {canciones.canciones_compartidas_dia || 0}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiLoveSong />
                  </Typography>
                  <Typography textAlign="center">
                    canciones esta semana
                  </Typography>
                  <Typography>
                    {canciones.canciones_compartidas_semana || 0}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiLoveSong />
                  </Typography>
                  <Typography textAlign="center">canciones este mes</Typography>
                  <Typography>
                    {canciones.canciones_compartidas_mes || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* canciones rechazadas */}
            <Stack>
              <Typography>Canciones Rechazadas</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* cards musicos */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiGuitarBassHead />
                  </Typography>
                  <Typography textAlign="center">Canciones hoy</Typography>
                  <Typography>
                    {cancionesR.canciones_rechazadas_dia || 0}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiGuitarBassHead />
                  </Typography>
                  <Typography textAlign="center">
                    Canciones esta semana
                  </Typography>
                  <Typography>
                    {cancionesR.canciones_rechazadas_semana || 0}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <GiGuitarBassHead />
                  </Typography>
                  <Typography textAlign="center">Canciones este mes</Typography>
                  <Typography>
                    {cancionesR.canciones_rechazadas_mes || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* compras realizadas */}
            <Stack>
              <Typography>Total compras (USD)</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* cards musicos */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <BsCashStack />
                  </Typography>
                  <Typography textAlign="center">Créditos hoy</Typography>
                  <Typography>{compras.compra_dia || 0} USD</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <BsCashStack />
                  </Typography>
                  <Typography textAlign="center">
                    Créditos esta semana
                  </Typography>
                  <Typography>{compras.compra_semana || 0} USD</Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                  <Typography fontSize="26px">
                    <BsCashStack />
                  </Typography>
                  <Typography textAlign="center">Créditos este mes</Typography>
                  <Typography>{compras.compra_mes || 0} USD</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <Typography>Reporte</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  gap: "10px",
                }}
              >
                {/* Generar Reporte Excel */}
                <Stack
                  alignItems="center"
                  py="10px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="#f1f1f1"
                >
                   <Box maxWidth="25px" width="100%">
                  <BottomNavigation
                    showLabels
                    value={value1}
                    onChange={(event, newValue) => {
                      descargarReporte();
                    }}
                  >
                    <BottomNavigationAction
                      icon={<ImFileExcel size={25}/>}
                    />
                  </BottomNavigation>
                </Box>
                  <Typography textAlign="center">Descargar Reporte</Typography>
                </Stack>
                
              </Stack>
            </Stack>

          </Stack>
          <Stack flexGrow="1" alignItems="center">
            <Typography variant="h6" textAlign="center">
              Artistas que enviaron más canciones
            </Typography>
            <Box maxWidth="300px" width="100%">
              <Box>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    handleChangeTimeArtist()
                  }}
                >
                  <BottomNavigationAction label="Dia" icon={<RestoreIcon />} />
                  <BottomNavigationAction
                    label="Semana"
                    icon={<RestoreIcon />}
                  />
                  <BottomNavigationAction label="Mes" icon={<RestoreIcon />} />
                </BottomNavigation>
              </Box>
              <Doughnut data={chartO} />
            </Box>
            <Typography variant="h6" textAlign="center" mt={2}>
              Curadores que recibieron mas canciones
            </Typography>
            <Box maxWidth="300px" width="100%">
              <Box maxWidth="300px" width="100%">
                <Box>
                  <BottomNavigation
                    showLabels
                    value={value1}
                    onChange={(event, newValue) => {
                      setValue1(newValue);
                      handleChangeTimeProducotres();
                    }}
                  >
                    <BottomNavigationAction
                      label="Dia"
                      icon={<RestoreIcon />}
                    />
                    <BottomNavigationAction
                      label="Semana"
                      icon={<RestoreIcon />}
                    />
                    <BottomNavigationAction
                      label="Mes"
                      icon={<RestoreIcon />}
                    />
                  </BottomNavigation>
                </Box>
              </Box>
              <Doughnut data={chart2} />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default Dashboard;
