import styled from "@emotion/styled";
import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CurrencyCodes } from "validator/lib/isISO4217";
import GlobalHeader from "../../../components/GlobalHeader";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import Audio from "react-loading-icons/dist/esm/components/audio";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const RetirosAdmin = () => {
  const [retiros, setRetiros] = useState([]);
  const { loadApi, loadingApi } = useApi();
  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  useEffect(() => {
    loadApi("admin/retiros", true, "get").then((resposne) => {
      setRetiros(resposne.data);
    });
  }, []);

  const handleUpdate = (id) => {
    loadApi(`admin/retiros/${id}`, true, "put", {})
      .then((res) => {
        showSnack(res.data.message);
      })
      .catch((e) => {
        showSnack(e.message, "error");
      })
      .finally(() => {
        setRetiros(retiros.filter((el) => el.retiro.id !== id));
      });
  };

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack pb={6}>
          <Typography variant="h4">Retiros pendientes</Typography>
          <Stack alignItems="end" sx={{ gap: "20px", mt: 2 }}>
            {/* <TextField variant="outlined" placeholder="Buscar..."></TextField> */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell> ID retiro </StyledTableCell>
                    <StyledTableCell> Nombres </StyledTableCell>
                    <StyledTableCell> Email de paypal </StyledTableCell>
                    <StyledTableCell> Monto </StyledTableCell>
                    <StyledTableCell> Acciones </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingApi.includes("get__admin/retiros") &&
                    retiros.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <Audio fill="#F27224" />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  {!loadingApi.includes("get__admin/retiros") &&
                  retiros.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5}>
                        <Typography variant="h5">No hay resultados</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    retiros.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{item.retiro?.id}</StyledTableCell>
                        <StyledTableCell>{item.retiro?.user?.name}</StyledTableCell>
                        <StyledTableCell>{item.email_paypal}</StyledTableCell>
                        <StyledTableCell>$ {item.retiro.monto} USD</StyledTableCell>
                        <StyledTableCell>
                          <LoadingButton
                            color="success"
                            variant="contained"
                            loading={loadingApi.includes(
                              `put__admin/retiros/${item.id}`
                            )}
                            onClick={() => handleUpdate(item.retiro.id)}
                          >
                            Marcar como pagado
                          </LoadingButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default RetirosAdmin;
