import styled from "@emotion/styled";
import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CurrencyCodes } from "validator/lib/isISO4217";
import GlobalHeader from "../../../components/GlobalHeader";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import Audio from "react-loading-icons/dist/esm/components/audio";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const HistorialRetiros = () => {
  const [retiros, setRetiros] = useState([]);
  const { loadApi, loadingApi } = useApi();

  useEffect(() => {
    loadApi("admin/retiros/historial", true, "get").then((resposne) => {
      setRetiros(resposne.data);
    });
  }, []);

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack pb={6}>
          <Typography variant="h4">Historial de retiros</Typography>
          <Stack alignItems="end" sx={{ gap: "20px", mt: 2 }}>
            {/* <TextField variant="outlined" placeholder="Buscar..."></TextField> */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell> ID retiro </StyledTableCell>
                    <StyledTableCell> Nombres </StyledTableCell>
                    <StyledTableCell> Email </StyledTableCell>
                    <StyledTableCell> Monto </StyledTableCell>
                    <StyledTableCell> Estado </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingApi.includes("get__admin/retiros") &&
                    retiros.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>
                          <Audio fill="#F27224" />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  {!loadingApi.includes("get__admin/retiros") &&
                  retiros.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5}>
                        <Typography variant="h5">No hay resultados</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    retiros.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{item.id}</StyledTableCell>
                        <StyledTableCell>{item.user.name}</StyledTableCell>
                        <StyledTableCell>{item.user.username}</StyledTableCell>
                        <StyledTableCell>$ {item.monto} USD</StyledTableCell>
                        {item.status !== "COMPLETED" ? (
                          <StyledTableCell>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                backgroundColor: "#b3b3b3",
                                borderRadius: "20px",
                                color: "graytext"
                              }}
                            >
                              {item.status}...
                            </Typography>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                backgroundColor: "green",
                                borderRadius: "20px",
                                color: "white"
                              }}
                            >
                              {item.status}
                            </Typography>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default HistorialRetiros;
