import { Container, Stack, TextField, Typography } from "@mui/material";
import GlobalHeader from "../../../components/GlobalHeader";
import { LoadingButton } from "@mui/lab";

import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";
import useApi from "../../../hooks/useApi";

const BoletinAdmin = () => {
  const [dataEmail, setDataEmail] = useState({});
  const { showSnack, openSB, typeSB, msgSB } = useSnack() 
  const { loadApi, loadingApi } = useApi()

  const handleSubmit = () => {
    loadApi("admin/correo", true, "post", dataEmail)
      .then((res)=> {
        showSnack(res.data.message, "success")
        setDataEmail({})
      }).catch((e)=> {
        console.log(e.message)
        showSnack(e.message, "error")
      })
  }

  return (
    <>
      <GlobalHeader />
      <Container>
        <Stack py={8}>
          <Typography variant="h4" mb={4}>
            Boletin de correos
          </Typography>
          <Stack spacing={3}>
            <TextField
              label="Para"
              type="text"
              variant="outlined"
              onChange={(e) =>
                setDataEmail({ ...dataEmail, to: e.target.value.toLocaleLowerCase() })
              }
            />
            <TextField
              label="Asunto"
              type="text"
              variant="outlined"
              onChange={(e) =>
                setDataEmail({ ...dataEmail, subject: e.target.value })
              }
            />
            <TextField
              label="Descripcion"
              type="text"
              multiline
              rows={5}
              variant="outlined"
              onChange={(e) =>
                setDataEmail({ ...dataEmail, description: e.target.value })
              }
            />
            <Stack alignItems="end">
              <LoadingButton
                sx={{ py: 1, px: 5 }}
                endIcon={<SendIcon />}
                loading={loadingApi.includes("post__admin/correo")}
                onClick={() => handleSubmit()}
              >
                Enviar
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
};

export default BoletinAdmin;
