import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import styles from "../../components/globalstyles";
import { Link } from "react-router-dom";

const Message = () => {
  return (
    <>
      {/* HEADER (SOLO MOVIL) */}
      <Box sx={styles.barramovil}>
        <img src="/assets/clipboard4.svg" alt="Radar Freim" />
      </Box>

      <Grid container sx={styles.root}>
        {/* VISTA IZQUIERDA */}
        <Grid item md={7} sx={styles.image}>
          <img alt="Radar Freim" src="/assets/logo.svg" width="40%" />
        </Grid>
        {/* VISTA DERECHA */}
        <Grid container item md={5} px={4}>
          <Stack
            width="100%"
            spacing={2}
            px={3}
            justifyContent="center"
            alignItems="center"
          >
            <ErrorOutlineRoundedIcon
              sx={{ color: "red", height: "auto", width: "40%" }}
            />
            <Typography align="center">
              Hubo un error en tu petición, intenta nuevamente.
            </Typography>
            <Button LinkComponent={Link} to="/">
              Continuar
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Message;
