import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import { useUser } from "../hooks/useUser";

function Redirect() {
  const navigate = useNavigate();
  const { loggedApi } = useApi();
  const { user } = useUser();

  useEffect(() => {
    if (!loggedApi) {
      navigate("/login");
    } else {
      user.role === "artista" && navigate("/artista/biblioteca");
      user.role === "productor" && navigate("/productor/envios");
      user.role === "admin" && navigate("/admin/dashboard");
    }
  }, []);

  return <></>;
}

export default Redirect;
