import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { Link } from "react-router-dom";
import IndCard from "./IndCard";

export default function VistaADashboard() {
  const { loadApi, loadedApi } = useApi();
  const [mainData, setMainData] = useState(Array.from({ length: 8 }));
  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  useEffect(() => {
    loadApi("artista/canciones", true, "get")
      .then((response) => {
        setMainData(response?.data || mainData);
      })
      .catch((e) => {
        showSnack(e.message, "error");
      });
  }, []);

  return (
    <>
      <GlobalHeader />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="primary.main" fontWeight="900">
                MIS CANCIONES
              </Typography>
              <Stack direction="row" spacing={1}>
                <Link to="/artista/nueva">
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: 40,
                      width: 40,
                      borderRadius: "50%",
                      bgcolor: "primary.main",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    <Typography fontSize="2rem">+</Typography>
                  </Box>
                </Link>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                my: 4,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: 2,
                pb: 2,
              }}
            >
              {mainData.map((dataItem, index) => (
                <IndCard
                  key={index}
                  data={dataItem}
                  loading={!loadedApi.includes("get__artista/canciones")}
                />
              ))}
            </Box>
            {mainData.length < 1 && (
              <Typography
                align="center"
                color="GrayText"
                fontWeight={600}
                variant="h5"
              >
                Aún no tienes canciones, crea la primera!
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
